import React, { Component } from "react"
import Mailchimp from "react-mailchimp-form"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import { ArrowButton } from "../buttons/Arrow_Link.js"

export default class SiteFooter extends Component {
	constructor(props) {
		super(props)
		this.links = this.props.links
		this.render_links = this.render_links.bind(this)
		this.query_links = this.query_links.bind(this)
		this.observer = null
		this.subscribed = 0
	}

	observerCallback = mutationList => {
		for (const mutation of mutationList) {
			if (mutation.type === "childList") {
				if (
					mutation.target.innerText === "Thank you for subscribing!" &&
					this.subscribed === 0
				) {
					this.subscribed = 1
					window.dataLayer = window.dataLayer || []
					window.dataLayer.push({
						event: "subscriptionCompleted",
					})
				}
			}
		}
	}

	footerDataLayer = () => {
		window.dataLayer = window.dataLayer || []
		window.dataLayer.push({
			event: "footer_menu",
		})
	}
	componentDidMount() {
		const targetNode = document.getElementsByClassName("msg-alert")[0]
		const config = { attributes: false, childList: true, subtree: false }
		this.observer = new MutationObserver(this.observerCallback)
		this.observer.observe(targetNode, config)

		const mcform = document.querySelector(
			".site-footer .email-form-container form"
		)
		const mcsub = document.querySelector(
			".site-footer .email-form-container form button"
		)

		mcsub.setAttribute("aria-label", "Submit button")
		const mcinput = document.querySelector(
			".site-footer .email-form-container form input"
		)
		const footer = document.querySelector(".site-footer")
		footer.addEventListener("keydown", e => {
			if (e.keyCode === 13) {
				e.preventDefault()
				footer.blur()
				mcform.blur()
				mcinput.blur()
				mcsub.focus()
				setTimeout(() => mcsub.click(), 1000)
			}
		})
	}

	componentWillUnmount() {
		this.observer.disconnect()
	}
	render_links(slug) {
		if (this.links) {
			var office_nav = this.query_links(slug)
			return office_nav.items.map(link => (
				<li key={link.title}>
					{link.url === "/birdwatch" ? (
						<a
							href="https://birdwatch.thecharlesnyc.com"
							title="Birdwatch"
							target="_blank"
							rel="noopener noreferrer"
						>
							<span dangerouslySetInnerHTML={{ __html: "Birdwatch" }} />
						</a>
					) : link.url.charAt(0) === "/" ? (
						<Link
							to={link.url}
							title={link.title}
							onClick={this.footerDataLayer}
						>
							<span dangerouslySetInnerHTML={{ __html: link.title }} />
						</Link>
					) : (
						<a
							href={link.url}
							title={link.title}
							target="_blank"
							rel="noopener noreferrer"
						>
							<span dangerouslySetInnerHTML={{ __html: link.title }} />
						</a>
					)}
				</li>
			))
		}
	}
	query_links(query) {
		return this.links.find(item => {
			if (item.slug === query) {
				return item
			}
			return null
		})
	}

	render() {
		return (
			<div className="site-footer" id="site-footer">
				<div className="grid">
					<div className="heron-logo grid-m-6 grid-t-8 grid-d-2">
						<StaticImage
							src="../../assets/images/the-charles-logo.png"
							alt="Logo"
							layout="fixed"
							height={100}
						/>
					</div>
					<div className="list-element grid-m-3 grid-t-8 grid-d-3">
						<h3 className="styling-eyebrow">Offices</h3>
						<ul>{this.render_links("offices-nav")}</ul>
					</div>
					<div className="list-element grid-m-3 grid-t-8 grid-d-3">
						<h3 className="styling-eyebrow">Company</h3>
						<ul>{this.render_links("company-nav")}</ul>
					</div>
					<div className="list-element grid-m-3 grid-t-8 grid-d-3">
						<h3 className="styling-eyebrow">Legal</h3>
						<ul>{this.render_links("legal-nav")}</ul>
					</div>
					<div className="grid-m-3 grid-t-8 grid-d-3">
						<a
							href="https://www.google.com/partners/agency?id=7062024387"
							target="_blank"
						>
							<img src="https://www.gstatic.com/partners/badge/images/2023/PartnerBadgeClickable.svg" />
						</a>
					</div>
					<div className="social-icons grid-m-3 grid-t-8 grid-d-5">
						<h3 className="styling-eyebrow">Follow</h3>
						<ul>{this.render_links("media-nav")}</ul>
					</div>
				</div>
				<div className="grid">
					<div className="email-signup grid-m-6 grid-t-16 grid-d-8 push-d-2">
						<h3 className="styling-eyebrow">Join our newsletter</h3>
						<div className="email-form-container">
							<Mailchimp
								id="mailchimp-email-form"
								action="https://thecharlesnyc.us4.list-manage.com/subscribe/post?u=5b76d600d742826acd5b885bd&amp;id=4af4b66498"
								fields={[
									{
										name: "EMAIL",
										placeholder: "Enter your email address",
										type: "email",
										required: true,
									},
								]}
								messages={{
									success: "Thank you for subscribing!",
									error: "An unexpected internal error has occurred.",
									empty: "You must write an e-mail.",
									duplicate:
										"Too many subscribe attempts for this email address",
								}}
							/>
							<ArrowButton title="subscribe" alignment="right" color="black" />
						</div>
					</div>
				</div>
				<div className="copyright">
					<p>
						©{new Date().getFullYear()} The Charles Group. <br /> All Rights
						Reserved.
					</p>
				</div>
			</div>
		)
	}
}
